import React from 'react';
import {Link} from 'gatsby';
import classnames from 'classnames';

import styles from './Button.module.css';

export function Button({block = false, to, ...props}) {
  const className = classnames(styles.Button, block && styles.block);

  return to == null ? (
    <button {...props} className={className} />
  ) : (
    <Link to={to} {...props} className={className} />
  );
}
