import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import Img from 'gatsby-image';

import styles from './nav.module.css';

export function Nav() {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <nav className={styles.Container}>
      <div className={styles.LogoContainer}>
        <Link to="/">
          <Img
            fluid={data.logo.childImageSharp.fluid}
            loading="eager"
            fadeIn={false}
            alt="Home"
          />
        </Link>
      </div>

      <div>
        <Link to="blog" className={styles.Link}>
          Blog
        </Link>
        <a href="https://admin.paywithplum.com" className={styles.Link}>
          Sign in
        </a>
      </div>
    </nav>
  );
}
