import './normalize.css';
import './global.css';

import React from 'react';
import PropTypes from 'prop-types';

import {Nav} from './nav';
import {Footer} from './footer';

import styles from './layout.module.css';

const Layout = ({children}) => {
  return (
    <>
      <div className={styles.Container}>
        <Nav />
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
