import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {Button} from '../components/common/Button';

import styles from './404.module.css';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={styles.Container}>
      <div>
        <h1 className={styles.Title}>Not found</h1>
        <p className={styles.Text}>We couldn't find the page you requested.</p>
        <Button to="/">Home &rarr;</Button>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
