import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import Img from 'gatsby-image';

import styles from './footer.module.css';

export function Footer() {
  const {logo} = useStaticQuery(graphql`
    query {
      logo: file(relativePath: {eq: "logo-white.png"}) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <footer className={styles.Container}>
      <div className={styles.InnerContainer}>
        <div className={styles.LogoContainer}>
          <Img
            fluid={logo.childImageSharp.fluid}
            loading="eager"
            fadeIn={false}
          />
        </div>

        <p className={styles.Text}>(801) 678-1915 • hello@paywithplum.com</p>

        <div className={styles.LinksContainer}>
          <Link className={styles.SmallLink} to="/privacy">
            Privacy
          </Link>
          <Link className={styles.SmallLink} to="/terms">
            Terms of service
          </Link>
          <Link className={styles.SmallLink} to="/blog">
            Blog
          </Link>
        </div>

        <p className={styles.Copyright}>&copy; 2019 Plum</p>
      </div>
    </footer>
  );
}
